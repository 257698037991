import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";

export const frontmatter = {
  title: "Día 31",
  week: "Semana 5",
  month: "abr",
  day: "13",
  monthNumber: "04",
  date: "2020-04-13",
  path: "/cronologia/semana-05#dia-13-abr",
};

const Day31 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDashedLine />
        <ModDataEu fecha={"2020-04-13"} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 3.477 nuevos positivos y 619 personas fallecidas. 3.282
          personas reciben el alta. El número de casos ingresados en la UCI
          asciende a 7.554, lo que supone un aumento de 19 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,2 % y la tasa de recuperados del 37,6 %.
        </ModText>
        <ModImage
          src="/images/svg/1304-covid-projects.svg"
          alt="investigación para el covid-19"
        />
        <ModText>
          El Centro para el Desarrollo Tecnológico Industrial (CDTI) aprueba{" "}
          <strong>4 proyectos frente al COVID-19</strong>, con una aportación
          pública de 1,8 millones de euros. Serán desarrollados por cuatro
          empresas de Andalucía, Cataluña y Madrid, y abordarán las temáticas de
          diagnóstico, desinfección y cuidados ante el coronavirus.
        </ModText>
        <ModText>
          El Ministerio de Justicia, el Consejo General del Poder Judicial,
          Fiscalía y comunidades autónomas acuerdan{" "}
          <strong>
            mantener los servicios esenciales en la Administración de Justicia
          </strong>
          . Además, han acordado autorizar, la{" "}
          <strong>presentación telemática de escritos</strong>, que había
          quedado limitada desde el pasado 18 de marzo.
        </ModText>
        <ModImage src="/images/svg/1304-court.svg" alt="administración de justicia" />
      </ContentRight>
    </LayoutDay>
  );
};
export default Day31;
